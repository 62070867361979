<template>
   <div class="form">
    <el-form class="content bascform" ref="form" :model="form" label-width="100px">
      <div class="col col4">
        <el-form-item label="拒绝原因" prop="RefusedText" :rules="{ required: true, message: '请输入拒绝原因', trigger: 'blur' }">
            <el-input type="textarea" v-model="form.RefusedText "  ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'RefuseBtnDialog',
  data: function () {
    return {
      form: {}
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';

</style>
